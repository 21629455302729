import React, {Component} from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"

import "./youtube.css"

class Youtube extends Component {
	
	state = {
		iframe: false
	}
	
	render(){
		
		//this.props;

		
		const iframe = this.state.iframe ? 
			<iframe id="ytplayer" type="text/html" src={this.props.src} frameBorder="0" allowFullScreen style={{width: this.props.width, height: this.props.height}}></iframe>
			:
			<div className="youtube" style={{width: this.props.width, height: this.props.height}}>
				<StaticImage
					 src="../images/video_prew.png"
					 alt="Vitaly Belov"
					 placeholder="blurred"
					 layout="fixed"
					 width={720}
					 height={405}
					 className="rounded pic_prew  mx-auto d-block"
				/>			
				<div className="play"></div>
			</div>

		
		return(
			<div onClick={this.loadIframe}>
				{iframe}
			</div>
		)
	}
	
	loadIframe = () =>{
		this.setState({iframe:true});
	}
	
}


/*function Youtube (props) {

	const {link}=props;
	
	//console.log(props);
	
	return(
		<div>
			<h1>YT</h1>
		</div>
	)

}*/

export default Youtube