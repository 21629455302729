import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"


import health_and_safety_black_48dp from "../images/health_and_safety_black_48dp.svg"
import money_off_black_48dp from "../images/money_off_black_48dp.svg"
import touch_app_black_48dp from "../images/touch_app_black_48dp.svg"
//import quotes from "../images/quotes.png"

//import LazyIframe from "../components/youtube"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Youtube from "../components/youtube"

const IndexPage = () => (
	<Layout>
		<Seo title="Home"/>
		<div className="container pt-5">
			<div className="row">
				<div className="col-lg-8">
				<p>Hello, friends! Belobot is a free robot for Olymp Trade. Belobot is able to independently make deals on the Olymp Trade platform using various strategies. The robot instantly analyzes the 10 most profitable pairs and selects those that differ from the trend. You can <a href="https://olymptrade.belobot.ru/download" target="_blank" rel="noreferrer">download a robot for Olymp Trade</a> for free and test it first on a demo account. If you like the robot and decide to trade on a real account, remember that the robot trades on a real account only if your account is registered using an <a href="https://olymptrade.belobot.ru/reg" target="_blank" rel="noreferrer">affiliate link</a>.<br/>Happy Bidding!</p>
				</div>
				<div className="col-lg-4 text-center">
				<StaticImage
					 src="../images/VitalyBelov.jpg"
					 alt="Vitaly Belov"
					 placeholder="blurred"
					 layout="fixed"
					 width={160}
					 height={160}
					 className="personPhoto"
				/>
				<p className="fw-bold mb-0">Vitaly Belov</p>
				<p className="text-muted">creator</p>
				</div>				
			</div>
		</div>
		<div className="container">
			<div className="row text-center">
				<h3>Try this Olymp Trade robot</h3>
				<div className="col-lg-4 justify-content-center">
					<div className="card m-4 text-center cardWidth bg-light bg-gradient">
						<div className="card-body">
							<h5 className="card-title">It is safe</h5>
							<img src={health_and_safety_black_48dp} className="mb-2"/>
							<h6 className="card-subtitle mb-2 text-muted">You do not risk your deposit</h6>
							<p className="card-text">Just install and try Olymp Trade Robot on a demo account. There are no restrictions. Try all strategies without risking your deposit.</p>
						</div>
					</div>
				</div>
				<div className="col-lg-4 justify-content-center">
					<div className="card m-4 text-center cardWidth bg-light bg-gradient">
						<div className="card-body">
							<h5 className="card-title">It's free</h5>
							<img src={money_off_black_48dp} className="mb-2"/>
							<h6 className="card-subtitle mb-2 text-muted">OlympTrade robot free</h6>
							<p className="card-text">Use Olymp Trade Bot for free with no time limit. You can trade both on a demo and on a real account. To trade on a real account, you only need to register it using an <a href="https://olymptrade.belobot.ru/reg" target="_blank" rel="noreferrer">affiliate link</a>.</p>
						</div>
					</div>
				</div>			
				<div className="col-lg-4 justify-content-center">
					<div className="card m-4 text-center cardWidth bg-light bg-gradient">
						<div className="card-body">
							<h5 className="card-title">It's easy</h5>
							<img src={touch_app_black_48dp} className="mb-2"/>
							<h6 className="card-subtitle mb-2 text-muted">You only need to choose a strategy</h6>
							<p className="card-text">It is enough to install Olymp Trade Bot and choose a strategy. The robot will conduct all trading on its own. You just have to watch the transactions.</p>
						</div>
					</div>
				</div>
			</div>	

			<div className="row">
				<h3 className="text-center">How it works</h3>
				<div className="col">
				<p className="text-center">To launch the Olymp Trade robot, you just need to install it and choose a strategy. Below is a detailed instruction.</p>
					<ol className="list-counter-circle">
						<li className="bg-gradient bg-dark">Register on the Olymp Trade platform</li>
						<p>If you already have an account on the Olymp Trade platform, you can use the robot on it. But in this case, you can trade the OlympTrade robot only on a demo account. In order for the robot to work on a real account, you need to log out of your account and register a new one using the <a href="https://olymptrade.belobot.ru/reg" target="_blank" rel="noreferrer">affiliate link</a>.</p>
						
						<li className="bg-gradient bg-dark">Download and Install OlympTrade Robot</li>
						<p>Olymp Trade robot works in the <a href="https://www.google.com/intl/ru_ru/chrome/" target="_blank" rel="noreferrer">chrome browser</a>. You just need to install the <a href="https://olymptrade.belobot.ru/download" target="_blank" rel="noreferrer">Belobot extension</a>.</p>
						
						<li className="bg-gradient bg-dark">Choose a strategy and launch OlympTrade robot</li>
						<p>Go to OlympTrade and launch the robot. Try different trading strategies and choose the best one. All strategies are described in the <Link to="/strategies">strategy section</Link>. If you have your own strategy, <Link to="/contacts">describe it</Link> and I will try to implement it in the OlympTrade robot.</p>

					</ol>
				</div>
			</div>				
			<div className="row text-center">	
				<div className="col text-center">
					<h3>Installation and configuration of OlympTrade robot</h3>
					<p>A short video on how to install and configure the OlympTrade robot. You can watch other videos about choosing a trading strategy and examples of the OlympTrade robot on <a href="https://www.youtube.com/channel/UClpv2djy_xmCtgQ4WOhz6vg" target="_blank" rel="noreferrer">our YouTube channel</a>. Do not forget to rate the video, ask questions in the comments and subscribe to the channel, and I will help you make a profit with Olymp Trade Robot. </p>
					<div className="d-md-block d-lg-none">
						<a href="https://youtu.be/rtVDNm4nUj0">
							<StaticImage
								 src="../images/video_prew.png"
								 alt="Vitaly Belov"
								 placeholder="blurred"
								 layout="fixed"
								 width={320}
								 height={180}
								 className="rounded pic_prew  mx-auto d-block"
							/>
						</a>
						
						
					</div>	
					<div className="d-none d-lg-block">

						<Youtube src="https://www.youtube.com/embed/rtVDNm4nUj0?iv_load_policy=3&autoplay=1" width={720} height={405} prew="123"/>
					
					</div>
				</div>				
			</div>
			<div className="row text-center">	
				<div className="col">			
					<h3>Warning for beginners</h3>
					<p>Remember that trading in financial markets is a high-risk business. You can increase your deposit or lose it. If this is your first time trading in the financial markets, use a demo account before switching to a real one. I hope the OlympTrade robot will become your indispensable assistant and bring great profits.<br/>Good luck!</p>
				</div>				
			</div>			
			
		</div>
	</Layout>
)

export default IndexPage
